@.counterSiteHandler = (energyCounterNumber, heatCounterNumber) ->
    $(document).ready( ->
        bindOdometerEnergyHandler()
        bindOdometerHeatHandler()
        bindCounterResizerHandler()
    )

    bindOdometerEnergyHandler = ->
        odometerEnergy = document.querySelector('#odometerEnergy')
        number = energyCounterNumber
        odometerEnergy = new Odometer(
            el: odometerEnergy
            value: number
            format: '(.ddd)'
            style: 'minimal'
        )

        month = moment().month()
        dateText = $('.jumbotron__overlay__counter-box__counter__date span')
        dateText.text(moment().format('DD.MM.YYYY'))
        energyIncrement = 2 * 2800 / 3600 if month in [0, 1, 2, 10, 11]
        energyIncrement = 2 * 2000 / 3600 if month in [3, 9]
        energyIncrement = 2 * 800 / 3600 if month in [4, 8]
        energyIncrement = 2 * 400 / 3600 if month in [5, 6, 7]

        setInterval(->
            number += energyIncrement
            odometerEnergy.update(parseInt(number))
            dateText.text(moment().format('DD.MM.YYYY'))
            return
        , 2000)

    bindOdometerHeatHandler = ->
        odometerHeat = document.querySelector('#odometerHeat')
        number = heatCounterNumber
        odometerHeat = new Odometer(
            el: odometerHeat
            value: number
            format: '(.ddd)'
        )

        month = moment().month()
        heatIncrement = 2 * 9.878 / 3600 if month in [0, 1, 2, 10, 11]
        heatIncrement = 2 * 7.056 / 3600 if month in [3, 9]
        heatIncrement = 2 * 2.822 / 3600 if month in [4, 8]
        heatIncrement = 2 * 1.411 / 3600 if month in [5, 6, 7]
        setInterval(->
            number += heatIncrement
            odometerHeat.update(parseInt(number))
            return
        , 2000)

    bindCounterResizerHandler = ->
        firstCounter = $(".jumbotron__overlay__counter-box__counter:first")
        SecondCounter = $(".jumbotron__overlay__counter-box__counter:nth-child(2)")
        firstCounterWidth = firstCounter.width()
        SecondCounterWidth = SecondCounter.width()
        if firstCounterWidth > SecondCounterWidth
            SecondCounter.width(firstCounterWidth)
        else
            firstCounter.width(SecondCounterWidth)
